import type { FlowEditorSDK, TFunction, WidgetBuilder } from '@wix/yoshi-flow-editor';
import { openManageMenuDashboardPanel } from 'root/utils/panels';

export const configureCommonGfpp = (widgetBuilder: WidgetBuilder, t: TFunction, editorSDK: FlowEditorSDK) => {
  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('app.gfpp.manageMenus'),
      onClick: () => {
        openManageMenuDashboardPanel(editorSDK);
      },
    })
    .set('mainAction2', { behavior: 'DEFAULT1' });

  widgetBuilder
    .gfpp('mobile')
    .set('mainAction1', {
      label: t('app.gfpp.manageMenus'),
      onClick: () => {
        openManageMenuDashboardPanel(editorSDK);
      },
    })
    .set('settings', {
      behavior: 'DEFAULT',
    });
};
