import type { EditorReadyFn, GetAppManifestFn, TFunction } from '@wix/yoshi-flow-editor';
import { WIDGET_IDS } from './consts';
import { configureCommonGfpp } from './editor/appManifest';
import { APP_DEF_IDS } from '@wix/restaurants-consts';

const TOKEN = '';
export const getAppManifest: GetAppManifestFn = ({ appManifestBuilder }, editorSDK, contextParams, flowAPI) => {
  const t = flowAPI.translations.t as TFunction;
  const manifest = appManifestBuilder.configureWidget(WIDGET_IDS.menus, (widgetBuilder) => {
    configureCommonGfpp(widgetBuilder, t, editorSDK);
  });
  return manifest.build();
};

export const editorReady: EditorReadyFn = async (editorSDK) => {
  const isMenusDashboardAppInstalled = await editorSDK.application.isApplicationInstalled(TOKEN, {
    appDefinitionId: APP_DEF_IDS.menus,
  });

  if (!isMenusDashboardAppInstalled) {
    await editorSDK.document.application.add(TOKEN, {
      appDefinitionId: APP_DEF_IDS.menus,
    });
  }
};
