import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { APP_DEF_IDS, DASHBOARD_URLS } from '@wix/restaurants-consts';

export const openManageMenuDashboardPanel = (editorSDK: FlowEditorSDK | null) => {
  if (editorSDK) {
    editorSDK.editor
      .openDashboardPanel(APP_DEF_IDS.menus, {
        url: DASHBOARD_URLS.menusUrl,
        closeOtherPanels: false,
      })
      .then(async () => {
        await editorSDK.document.application.livePreview.refresh('', {
          shouldFetchData: true,
          source: '',
        });
        await editorSDK.document.application.reloadManifest();
      });
  }
};
